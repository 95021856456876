<template>
    <!-- SAVE DIALOG-->
    <div class="text-right">
        <v-dialog
        persistent
        v-model="saveQuoteDialog"
        width="385"
        >
            <v-card>
                <v-card-title class="d-flex justify-end pb-0">
                    <v-btn icon
                        @click="this.closeSendQuoteDialog"
                        aria-label="Send Quote to email">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center align-center flex-column">
                            <div class="text-center mb-4"><v-icon class="xxxxx-large auto-height">{{ this.saveQuoteDialogSave ? this.saveQuoteDialogSaveImage : this.saveQuoteDialogLeaveImage }}</v-icon></div>
                            <h3 class="ml_darkergray--text mb-0">{{this.saveQuoteDialogSave ? saveQuoteDialogSaveTitle : saveQuoteDialogLeaveTitle  }}</h3>
                            <!-- <p class="body-2 mt-3 mb-0 text-left ml_darkergray--text" v-if="!this.saveQuoteDialogSave">Enter your email or phone number below to save your quote and come back to your quote at any time through a link.</p> -->
                            <p class="body-2 mt-3 mb-0 text-left ml_darkergray--text" v-if="!this.saveQuoteDialogSave">Enter your email below to save your quote and come back to your quote at any time through a link.</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="12" class="py-0">
                            <v-radio-group class="mt-0" mandatory dense v-model="saveQuoteMethod">
                                <v-radio label="Email" :value="1"></v-radio>
                                <v-radio label="Mobile phone number (MMS)" :value="2"></v-radio>
                            </v-radio-group>
                        </v-col> -->
                        <v-col cols="12" class="pt-0">
                            <v-text-field v-model="sendQuoteToMail" type="email" :rules="existingUserRules.email" label="Email" solo outlined  v-if="this.saveQuoteMethod === 1"></v-text-field>
                            <!-- <v-text-field v-model="sendQuoteToPhone" type="tel"  :rules="existingUserRules.phone"  label="Phone" solo outlined  v-if="this.saveQuoteMethod === 2"></v-text-field> -->
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex flex-column justify-center">
                    <v-btn 
                        color="primary"
                        @click="sendQuotetoSelected"
                        :loading="this.sendQuoteProcessing"
                        :disabled="this.sendQuoteProcessing || this.sendQuoteDone  || this.sendQuoteInvalid"
                    >
                        {{this.sendQuoteDone ?  this.saveQuoteDialogTextSubmitted : this.saveQuoteDialogTextSubmit}}
                    </v-btn>
                    <div v-if="this.sendQuoteError" class="v-messages theme--light error--text pt-2" role="alert">
                        <div class="v-messages__wrapper">
                            <div  class="v-messages__message"><span id="missingPetBreedMessage">{{ sendQuoteErrorText }}</span>
                            </div>
                        </div>
                    </div>
                    <v-btn
                        color="primary"
                        text
                        :disabled="this.sendQuoteProcessing || this.sendQuoteDone"
                        @click="this.closeSendQuoteDialog"
                    >
                        {{ this.sendQuoteDone ? saveQuoteDialogTextDone : saveQuoteDialogTextCancel }}{{ this.sentQuoteTimer ?  '(' + this.sentQuoteTimer + ')' :  ''}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {

    name: 'saveQuoteDialog',
    props: {
        onComplete: Function,
    },
    computed: {
        sendQuoteToEmail: {
            get() {
                return this.$store.getters.owner.email;
            },
        },
        saveQuotePhone: {
            get() {
                return this.$store.getters.saveQuotePhone;

            }
        },
        saveQuoteDialog: {
            get() {
                return this.$store.getters.saveQuoteDialog;
            },
            set(value) {
                console.log(value)
                this.$store.commit("setSaveQuoteDialog", value);
            }
        }
    },
    data:() => ({
        saveQuoteDialogSave: false,
        saveQuoteDialogSaveImage: '$sendQuoteDog',
        saveQuoteDialogSaveTitle: 'How would you like to save your quote?',
        saveQuoteDialogLeaveImage: '$sendQuoteBone',
        saveQuoteDialogLeaveTitle: 'Not ready? Save your quote',
        saveQuoteDialogTextSubmit: 'Save and send quote', 
        saveQuoteDialogTextSubmitted: 'Quote saved and sent', 
        saveQuoteDialogTextCancel: 'No Thanks',
        saveQuoteDialogTextDone: 'Done',
        saveQuoteMethod: 1,
        sendQuoteToMail: '',
        sendQuoteToPhone: '',
        sendQuoteInvalid: false, 
        sendQuoteProcessing: false,
        sendQuoteDone: false,
        sendQuoteError: false,
        sendQuoteErrorText: "Can't sent to selected method.",
        sentQuoteTimer: null,
        existingUserRules: {
            phone: [
                value => !!value || "Please enter a phone number (Required).",
                value => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value) || 'Please Enter a valid phone number.'
            ],
            email: [
                value => !!value || "Please enter an e-mail (Required).",
                value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(value) || 'Please enter a valid e-mail address.'
            ]
        },
    }),
    mounted() {
        this.sendQuoteToMail = this.sendQuoteToEmail;
        this.sendQuoteToPhone = this.saveQuotePhone;
    },
    watch: {
        saveQuoteMethod: function(newVal, oldVal) { 
            if(newVal !== oldVal){
                if(newVal === 1){

                    if(this.sendQuoteToMail === ''){
                        this.sendQuoteInvalid = true;
                    }else{
                        this.onDemandSendQuotetoMailValidate();
                    }
                }else if(newVal === 2){
                    if(this.sendQuoteToPhone === ''){
                        this.sendQuoteInvalid = true;
                    }else{
                        this.onDemandSendQuotetoPhoneValidate();
                    }
                }
            }
        },
        sendQuoteToEmail: function(newVal, oldVal) {
            this.sendQuoteToMail = newVal;
        },
        saveQuotePhone: function(newVal, oldVal) {
            this.sendQuoteToPhone = newVal;
        },
        sendQuoteToMail() {
            this.onDemandSendQuotetoMailValidate();
        }, 
        sendQuoteToPhone() {
            this.onDemandSendQuotetoPhoneValidate();
        },
    },
    methods: {
        sendQuotetoSelected() {
            var ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: 'Save and Send Quote',
                    stepName: 'Plan Selection'
                }
            }
            digitalData.eventTrack('linkClick', ldo);

            let selectedMethod = null;
            if(this.saveQuoteMethod === 1 && this.sendQuoteToMail !== ''){
                this.sendQuoteError = false;
                selectedMethod = this.sendQuoteToMail;
                this.sendQuoteProcessing = true;
                this.processQuote(selectedMethod, "Email");

            }else if(this.saveQuoteMethod === 2 && this.sendQuoteToPhone !== '' ){
                this.sendQuoteError = false;
                selectedMethod = this.sendQuoteToPhone.replace(/\D/g,"");
                this.sendQuoteProcessing = true;
                this.processQuote(selectedMethod, "Phone");

            }else{
                this.sendQuoteError = true;
            }
        }, 
        async processQuote(value, method){
            if(value !== null) {

                let parameters = method === "Email" 
                    ? { email: value } 
                    : { phone: value }

                await this.$store.dispatch("saveQuoteByPhoneOrMail", parameters)
                    .then(async (response) => {
                        if (response.data.Data.Id) {
                            this.sendQuoteProcessing = false;
                            this.sendQuoteDone = true;
                            this.$store.commit("setQuoteSaved", true);
                            this.sentQuoteTimer = 3;
                            const timer = setInterval(()=>{
                                this.sentQuoteTimer--;
                            },1000);
                            setTimeout(() => {
                                clearInterval(timer);
                                this.onComplete("quoteSaved");
                            }, 3000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Saving Quotes',
                                text: 'An error occurred while Saving your quote. Please try again later. If this problem persist, please contact support.',
                                confirmationButton: "Okay"
                            });
                        }
                    })
            }
        }, 
        async closeSendQuoteDialog() {
            await this.$store.commit("setSaveQuoteDialog", false);
            this.sendQuoteError = false;
            this.sendQuoteProcessing = false;
            this.sendQuoteDone = false;
            this.onComplete("quoteCanceled");

            var link_name = this.sentQuoteTimer == null ? "No Thanks" : "Done";
            var ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: link_name,
                    stepName: 'Plan Selection'
                }
            }
            digitalData.eventTrack('linkClick', ldo);
        },
        onDemandSendQuotetoMailValidate(){
                // console.log(this.existingUserRules.email.map( rule => rule(this.sendQuoteToMail)).filter(message => message));
                if(this.existingUserRules.email.map( rule => rule(this.sendQuoteToMail)).filter(message => message).every(item => item === true)){
                    if(this.saveQuoteMethod === 1){
                        this.sendQuoteInvalid = false;
                    }
                }else{
                    if(this.saveQuoteMethod === 1){ 
                        this.sendQuoteInvalid = true;
                    }
                }
        }, 
        onDemandSendQuotetoPhoneValidate(){
            // console.log(this.existingUserRules.phone.map( rule => rule(this.sendQuoteToPhone)).filter(message => message));
            if(this.existingUserRules.phone.map( rule => rule(this.sendQuoteToPhone)).filter(message => message).every(item => item === true)){
                if(this.saveQuoteMethod === 2){
                    this.sendQuoteInvalid = false;
                }
            }else{
                if(this.saveQuoteMethod === 2){ 
                    this.sendQuoteInvalid = true;
                }
            }
        },

    }
}
</script>