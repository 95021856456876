<template>
    <v-row justify="center">
    <v-dialog
        max-width="1440px"
        v-model="coverageDialog"
        transition="dialog-bottom-transition"
        id="dialogPlanCoverage"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
          <v-row>
              <v-col cols="12" class="text-center mb-2">
                <v-btn
                id="dialogPlanCoverageBtnTrigger"
                text
                color="primary"
                class="px-0 btn-wrap"
                v-on="on"
                >
                    <v-icon large class="pb-2">$coverageIcon</v-icon>
                    <b>See what MetLife Pet Insurance Covers</b>
                   
                </v-btn>
                <a aria-label="Go to Footnote 2 on Coverage." id="anchorFootnote2" ref="anchorFootnote2" @click="goToElementAndFocus('footnote-2', 'footnote2')"><sup>[2]</sup></a>  
            </v-col>

        </v-row>
      </template>

      <v-card 
        color="ml_lightgray"  
        role="dialog"
        aria-modal="true"
        tabindex="-1"  
        aria-labelledby="dialogPlanCoverageTitle">
        <v-card-title>
          <span role="heading" aria-level="2" id="dialogPlanCoverageTitle">Plans Coverage</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            aria-label="Close Plan Coverage Dialog"
            @click="coverageDialog = false"
            @keydown="handleCloseKeydown"
            ref="closeCoverageButton"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span class="d-sr-only" aria-live="polite">{{closeSRAnnouncement}}</span>
        </v-card-title>
        <v-divider role="none"></v-divider>
        <v-card-text class="mt-3">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card elevation="1" class="mx-auto">
                        <v-card-title id="planInclussionTitle" role="heading" aria-level="3">All Plans Include</v-card-title>
                        <v-card-text>
                            <v-row class="py-4" id="planInclussionDetails" tabindex="0" role="region" aria-labelledby="planInclussionTitle">
                                <v-col class="py-0" cols="12" md="6">
                                    <ul class="list-coverage">
                                        <li  aria-label="Accidents,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Accidents</li>
                                        <li  aria-label="Illness,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Illness</li>
                                        <li  aria-label="Hospitalizations,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Hospitalizations</li>
                                        <li  aria-label="Surgeries,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Surgeries</li>
                                        <li  aria-label="Diagnostic,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Diagnostic Tests</li>
                                        <li  aria-label="Exam Fees,"><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Exam Fees</li>
                                    </ul>
                                </v-col>
                                <v-col class="py-0"  cols="12" md="6">
                                    <ul class="list-coverage">
                                        <li aria-label="X-Rays," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> X-Rays</li>
                                        <li aria-label="Ultrasounds," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Ultrasounds</li>
                                        <li aria-label="Medications," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Medications</li>
                                        <li aria-label="Holistic Care & Alternative Therapies," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Holistic Care & Alternative Therapies</li>
                                        <li aria-label="Holistic Care & Alternative Therapies," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Prescription food and prescribed vitamins and supplements</li>
                                        <li aria-label="And Much More!," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> And Much More!</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="!isMetGen">
                    <v-card elevation="1" class="mx-auto" >
                        <v-card-title id="planInclussionAfterPeriodTitle" aria-label="After a 6-month wait Period, Your pet is also covered for:">After a 6-month wait Period</v-card-title>
                        <v-card-subtitle>Your pet is also covered for:</v-card-subtitle>
                        <v-card-text>
                            <v-row class="py-4" id="planInclussionAfterPeriodDetails" tabindex="0" role="region" aria-labelledby="planInclussionAfterPeriodTitle">
                                <v-col class="py-0" cols="12" md="6">
                                    <ul class="list-coverage">
                                        <li aria-label="Intervertebral Disc Disease (IVDD),"><v-icon  color="secondary">mdi-checkbox-marked-outline</v-icon> Intervertebral Disc Disease (IVDD)</li>
                                        <li aria-label="Anterior Crucate Ligaments," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Anterior Crucate Ligaments</li>
                                        <li aria-label="Medical Cruciate," ><v-icon color="secondary">mdi-checkbox-marked-outline</v-icon> Medical Cruciate</li>
                                    </ul>
                                </v-col>
                                <v-col class="py-0" cols="12" md="6">
                                    <ul class="list-coverage">
                                        <li aria-label="Posterior Cruciate Ligaments,"><v-icon  color="secondary">mdi-checkbox-marked-outline</v-icon> Posterior Cruciate Ligaments</li>
                                        <li aria-label="Cranial Cruciate Ligaments,"><v-icon  color="secondary">mdi-checkbox-marked-outline</v-icon> Cranial Cruciate Ligaments</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card elevation="1" class="mx-auto" >
                        <v-card-title id="planNotIncludedTitle" role="heading" aria-level="3">Not Covered</v-card-title>
                        <v-card-text>
                            <v-row class="py-4" id="planNotIncludedDetails" tabindex="0" role="region" aria-labelledby="planNotIncludedTitle">
                                <v-col class="py-0" cols="12">
                                    <ul class="list-coverage">
                                        <li class="ml_darkgray--text" aria-label="Pre-existing Conditions ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Pre-existing Conditions</li>
                                        <li class="ml_darkgray--text" aria-label="Elective Procedures ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Elective Procedures</li>
                                        <li class="ml_darkgray--text" aria-label="Expression or removal of anal glands or anal sacculittis ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Expression or removal of anal glands or anal sacculittis</li>
                                        <li class="ml_darkgray--text" aria-label="Breeding or conditions related to breeding ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Breeding or conditions related to breeding</li>                                        
                                        <li class="ml_darkgray--text" aria-label="Grooming costs and bathing ( inc. medicated baths) ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Grooming costs and bathing ( inc. medicated baths)</li>
                                        <li class="ml_darkgray--text" aria-label="Parasite prevention and parasite treatment ,"><v-icon color="ml_darkgray">mdi-close-box-outline</v-icon> Parasite prevention and parasite treatment</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
    name: 'CoverageDialog',
    methods: {
        handleCloseKeydown(e) {
                if(e.key === "Tab") {
                    e.preventDefault();
                    this.closeSRAnnouncement = "Ensure coverage details are read. Press enter to close the dialog.";
                    setTimeout(() => {
                        this.closeSRAnnouncement=""
                    },500);
                }
            },
            toggleHideBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.setAttribute("aria-hidden","true");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for hiding-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.setAttribute("aria-hidden","true");
                })
            },
            toggleShowBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.removeAttribute("aria-hidden");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for showing-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.removeAttribute("aria-hidden");
                })
         },
    },

    props: {
            isMetGen: false,
    },
    data: () => ({
        coverageDialog: false,
        closeSRAnnouncement: "",
    }),
    watch: {
        coverageDialog: function (coverageDialog) {
            if(coverageDialog){
                this.goToElementAndFocus('dialogPlanCoverage'); 
                this.toggleHideBackgroundContianer();
            }else{
                this.toggleShowBackgroundContianer();
                this.goToElementAndFocus('dialogPlanCoverageBtnTrigger');
            }
        }
        
    },
    updated() {
        if(this.coverageDialog) {
            const interval = setInterval(() => {
                if(this.$refs.closeCoverageButton) {
                this.$nextTick(()=>this.$refs.closeCoverageButton.$el.focus());
                clearInterval(interval);
                }
        }, 50);
        }
    }
}
</script>