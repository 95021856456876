<template>
  <div v-if="modal.show" :class="modal.show ? 'modal modal--open' : 'modal modal--closed'">
    <div class="modal--inner">
      <a v-if="modal.content.buttons.cancel !== ''" class="modal--close" @click="closeModal(false)">
        <i class="fa fa-times close-circle--icon" aria-hidden="true"></i>
      </a>
      <h3 class="modal--heading bold">
        <slot name='modal-heading'></slot>
      </h3>
      <div class="modal--body">
        <slot name='modal-body'></slot>
      </div>
      <div class="modal--buttons">
        <button tabindex="-1" class="btn-primary btn-lg" type="submit" @click="closeModal(true)">
          <slot name='modal-button--submit'></slot>
        </button>
        <button v-if="modal.content.buttons.cancel !== ''" class="btn-secondary btn-lg" @click="closeModal(false)">
          <slot name='modal-button--cancel'></slot>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    modal() {
      return this.$store.getters.modal;
    }
  },
  mounted() {    
    if(typeof this.modal.init === 'function'){      
      this.modal.init();
    }
    
  },
  methods: {
    closeModal(shouldSubmit) {      
        this.modal.return = shouldSubmit;
        this.modal.show = false;
        if (shouldSubmit && typeof this.modal.submit === 'function') {
            this.modal.submit();
        } else if (typeof this.modal.cancel === 'function') {
            this.modal.cancel();
        }
        setTimeout(() => {
            this.modal.return = undefined;
            this.$store.commit('clearModal');
        }, 350)
    }
  }
}
</script>
