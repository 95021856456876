<template>
    <v-app>
        <SaveQuoteDialog :onComplete="$event => closeWindow($event)"></SaveQuoteDialog>

        <div aria-live="polite" :class="isLoading ? '' : 'd-sr-only'">
            <v-overlay absolute color="white" v-if="isLoading" style="z-index: 6; width: 101%;">
                <div class="dot-typing"></div>
                <span class="d-sr-only">Loading... Please Wait!</span>
            </v-overlay> 
        </div>

        <v-app-bar app color="white" aria-label="Header" ref="headerRef">
            <div id="logo-wrapper" class="d-flex align-center ma-0">
                <v-img 
                    alt="Partner Logo"
                    class="shrink"
                    contain
                    :src="partnerLogoSource"
                    transition="scale-transition"
                    :max-width="getPartnerLogoWidth()"
                    max-height="50"
                    width="auto"
                    v-on:click="goToLogoURL(partnerLogoUrl)"
                    :data-partnerID="this.$store.getters.partnerId"
                    v-if="partnerLogoSource !== ''"
                />
                <v-divider role="none" v-if="partnerLogoSource !== ''" class="mx-4" vertical></v-divider>
                <v-img v-if="logoSource"
                       alt="MetLife Logo"
                       class="shrink mr-2"
                       contain
                       :src="logoSource"
                       transition="scale-transition"
                       width="auto"
                       max-height="56"
                       max-width="250"
                />
                <v-img v-else
                       alt="MetLife Logo"
                       class="shrink mr-2"
                       contain
                       src="@/assets/images/ML_PetInsurance_Snoopy_Horizontal_Color_March.png"
                       transition="scale-transition"
                       width="auto"
                       max-height="56"
                       max-width="400"
                />      
            </div>

            <v-spacer></v-spacer>
            <v-btn icon color="black" class="btn-vertical" v-on:click="closeWindow" aria-label="Exit/Logout Metlife Quote Funnel" ref="exitButtonApp">
                <v-icon>mdi-close</v-icon> 
                <span>Exit</span>
            </v-btn>
        </v-app-bar>

        <v-main>
            
            <router-view />
            <transition name="modal">
                <Modal :key=modalKey>
                    <span slot="modal-heading">{{ modal.content.heading }}</span>
                    <div slot="modal-body"><div v-html="modal.content.body"></div></div>
                    <span slot="modal-button--submit">{{ modal.content.buttons.submit }}</span>
                    <span slot="modal-button--cancel">{{ modal.content.buttons.cancel }}</span>
                </Modal>
            </transition>
        </v-main>
       
        <div class="global-footer">
            <Footer ref="footer" :onClose="$event => closeWindow($event)"></Footer>
        </div>
    </v-app>
</template>
<script>
    import Modal from '../Modal.vue';
    import Footer from '@/components/shared/Footer.vue';
    import SaveQuoteDialog from '@/components/shared/SaveQuoteDialog.vue';
    import VueCookies from 'vue-cookies';
    import layout from './Global/JS/layout.js';
    export default {
        mixins: [layout],
        components: {
            Modal, Footer, SaveQuoteDialog
        },
        data () {
            return {

            }
        },
        methods: {
            handleZoomControlforHeader(){
                let headerElement = this.$refs.headerRef.$el; 
                headerElement.classList.add("header-position");
            },
            retainBackZoomControlClassesforHeader(){
                let headerElement = this.$refs.headerRef.$el;
                headerElement.classList.remove("header-position");
            },
            async closeWindow(callFrom) {
                if(this.canShowSaveDialog()) {
                    if(!this.quoteSaved && callFrom !== "quoteCanceled") this.$store.commit("setSaveQuoteDialog", true);

                    if(!this.quoteSaved && callFrom === "quoteCanceled") this.showSwalClose(callFrom);

                    if(!this.quoteSaved && window.location.href.indexOf('quotes') > 0 && callFrom !== "quoteCanceled") this.$store.commit("setSaveQuoteDialog", true);;

                    if(this.quoteSaved && callFrom === "quoteSaved") {
                        this.$store.dispatch("analyticsExit");
                        sessionStorage.clear();
                        this.$store.reset();
                        window.location.replace('https://www.metlifepetinsurance.com/pet-insurance');
                    }

                    if(this.quoteSaved) this.showSwalClose(callFrom);
                } else {
                    this.showSwalClose(callFrom);
                }
                
            },  
        },
        created() {
            
        },
        mounted() {
            window.addEventListener("resize",(e) => {
            let zoomLevel = Math.round(((window.outerWidth) / window.innerWidth) * 100 + 1);
            if(zoomLevel >= 350){
                     setTimeout(() => {
                        this.handleZoomControlforHeader();
                    }, 100)
                } else {
                    this.retainBackZoomControlClassesforHeader();
                }
            });
            window.addEventListener("scroll",(e)=>{
                this.handleZoomControlforHeader();
            })
        },
        updated(){
            window.addEventListener("resize",(e) => {
            let zoomLevel = Math.round(((window.outerWidth) / window.innerWidth) * 100 + 1);
            if(zoomLevel >= 350){
                     setTimeout(() => {
                        this.handleZoomControlforHeader();
                    }, 100)
                } else {
                    this.retainBackZoomControlClassesforHeader();
                }
            });
            window.addEventListener("scroll",(e)=>{
                this.handleZoomControlforHeader();
            })
        }
    }
</script>
<style>
    .header-position{
        position: relative !important;
    }
</style>
